define("discourse/plugins/discourse-babypips-theme/discourse/templates/connectors/user-preferences-nav/manage-subscription", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <PremiumOnly>
    <form method="POST" action={{main-site-url-for "/subscribe/manage"}}>
      <button type="submit">
        {{d-icon "hat-cowboy"}}
        Manage Subscription
      </button>
    </form>
  </PremiumOnly>
  */
  {
    "id": "J6ahBA/N",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[10,\"form\"],[14,\"method\",\"POST\"],[15,\"action\",[28,[37,1],[\"/subscribe/manage\"],null]],[12],[1,\"\\n    \"],[10,\"button\"],[14,4,\"submit\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"hat-cowboy\"],null]],[1,\"\\n      Manage Subscription\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"premium-only\",\"main-site-url-for\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-babypips-theme/discourse/templates/connectors/user-preferences-nav/manage-subscription.hbs",
    "isStrictMode": false
  });
});