define("discourse/plugins/discourse-babypips-theme/initializers/topic", ["exports", "discourse/routes/topic", "discourse/lib/ajax"], function (_exports, _topic, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "topic",
    initialize() {
      _topic.default.reopen({
        redirect() {
          // Check if the URL anchor includes post ID. If so, transition to that post.
          const matches = /#post(\d+)/i.exec(window.location.hash);
          if (matches !== null && matches.length > 0) {
            (0, _ajax.ajax)("/vbulletin/post/" + matches[1] + ".json").then(post => {
              this.transitionTo("topic.fromParamsNear", {
                nearPost: post.post_number
              });
            });
          } else {
            this._super();
          }
        }
      });
    }
  };
});