define("discourse/plugins/discourse-babypips-theme/discourse/components/adhesion-ad", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/plugins/discourse-babypips-theme/discourse/components/stickybar", "discourse/plugins/discourse-babypips-theme/discourse/lib/tracked-async-data", "@ember/template-factory", "@ember/component"], function (_exports, _component, _tracking, _object, _service, _stickybar, _trackedAsyncData, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AdhesionAd = _exports.default = (_class = (_class2 = class AdhesionAd extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "adhesionAd", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
    }
    get ad() {
      const ad1 = this.adhesionAd.getAd();
      return new _trackedAsyncData.default(ad1);
    }
    get shouldShowStickybar() {
      return this.ad.value !== undefined && this.ad.value !== null && !this.adhesionAd.closed;
    }
    get isThirdParty() {
      return this.ad.value !== undefined && this.ad.value !== null && this.ad.value.thirdParty;
    }
    renderThirdParty() {
      window.freestar = window.freestar || {
        queue: []
      };
      window.freestar.queue.push(function () {
        window.freestar.newStickyFooter("babypips_sticky_footer");
      });
    }
    onImpression() {
      this.adhesionAd.impression(this.router.currentURL);
    }
    onClose(e1) {
      this.adhesionAd.close(e1);
    }
    onClick(e1) {
      this.adhesionAd.click(e1);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if this.ad.isResolved}}
        {{#if this.shouldShowStickybar}}
          {{#if this.isThirdParty}}
            <div id="stickybar" data-campaign={{this.ad.value.slug}} />
            {{(this.renderThirdParty)}}
          {{else}}
            <Stickybar
              @ad={{this.ad.value}}
              @onClose={{this.onClose}}
              @onClick={{this.onClick}}
            />
          {{/if}}
          {{(this.onImpression)}}
        {{/if}}
      {{/if}}
    
  */
  {
    "id": "PRwjdoRK",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"ad\",\"isResolved\"]],[[[41,[30,0,[\"shouldShowStickybar\"]],[[[41,[30,0,[\"isThirdParty\"]],[[[1,\"          \"],[10,0],[14,1,\"stickybar\"],[15,\"data-campaign\",[30,0,[\"ad\",\"value\",\"slug\"]]],[12],[13],[1,\"\\n          \"],[1,[28,[30,0,[\"renderThirdParty\"]],null,null]],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[32,0],null,[[\"@ad\",\"@onClose\",\"@onClick\"],[[30,0,[\"ad\",\"value\"]],[30,0,[\"onClose\"]],[30,0,[\"onClick\"]]]],null],[1,\"\\n\"]],[]]],[1,\"        \"],[1,[28,[30,0,[\"onImpression\"]],null,null]],[1,\"\\n\"]],[]],null]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-babypips-theme/discourse/components/adhesion-ad.js",
    "scope": () => [_stickybar.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "adhesionAd", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "ad", [_tracking.cached], Object.getOwnPropertyDescriptor(_class.prototype, "ad"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "renderThirdParty", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "renderThirdParty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onImpression", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onImpression"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClose", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClose"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype)), _class);
});