define("discourse/plugins/discourse-babypips-theme/discourse/helpers/settings", ["discourse-common/lib/helpers", "discourse/plugins/discourse-babypips-theme/discourse/lib/settings"], function (_helpers, _settings) {
  "use strict";

  (0, _helpers.registerHelper)("bp-env", function (v) {
    return (0, _settings.env)() === v[0];
  });
  (0, _helpers.registerHelper)("site-header-env", function () {
    return (0, _settings.env)() === "production" ? "site-header" : "site-header " + (0, _settings.env)();
  });
  (0, _helpers.registerHelper)("main-site-url-for", function (path) {
    return (0, _settings.mainSiteURLFor)(path);
  });
  (0, _helpers.registerHelper)("marketmilk-url-for", function (path) {
    return (0, _settings.marketmilkURLFor)(path);
  });
  (0, _helpers.registerHelper)("publisher", function (key) {
    return (0, _settings.publisher)()[key];
  });
  (0, _helpers.registerHelper)("current-year", function () {
    return new Date().getFullYear();
  });
});