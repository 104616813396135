define("discourse/plugins/discourse-babypips-theme/discourse/components/premium-only", ["exports", "@ember/component", "@ember/service", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _service, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldShow}}
  {{yield}}
  {{/if}}
  */
  {
    "id": "VFxinUki",
    "block": "[[[41,[30,0,[\"shouldShow\"]],[[[18,1,null],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "discourse/plugins/discourse-babypips-theme/discourse/components/premium-only.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend((_dec = (0, _decorators.default)("currentUser.custom_fields.premium_user"), (_obj = {
    router: (0, _service.inject)(),
    tagName: "",
    shouldShow(isPremium) {
      return isPremium === "true" || isPremium === true;
    }
  }, (_applyDecoratedDescriptor(_obj, "shouldShow", [_dec], Object.getOwnPropertyDescriptor(_obj, "shouldShow"), _obj)), _obj))));
});