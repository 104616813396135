define("discourse/plugins/discourse-babypips-theme/discourse/lib/settings", ["exports", "discourse/lib/preload-store"], function (_exports, _preloadStore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.env = env;
  _exports.mainSiteURLFor = mainSiteURLFor;
  _exports.marketmilkURLFor = marketmilkURLFor;
  _exports.publisher = publisher;
  function env() {
    const bpSettings = _preloadStore.default.get("bpSettings");
    return bpSettings ? bpSettings.env : "production";
  }
  function publisher() {
    const bpSettings = _preloadStore.default.get("bpSettings");
    return bpSettings ? bpSettings.publisher : {};
  }
  function mainSiteURLFor(path) {
    const bpSettings = _preloadStore.default.get("bpSettings");
    return bpSettings ? `${bpSettings.frontend}${path}` : null;
  }
  function marketmilkURLFor(path) {
    const bpSettings = _preloadStore.default.get("bpSettings");
    return bpSettings ? `${bpSettings.marketmilk}${path}` : null;
  }
});