define("discourse/plugins/discourse-babypips-theme/discourse/services/adhesion-ad", ["exports", "@glimmer/tracking", "@ember/service", "discourse/plugins/discourse-babypips-theme/discourse/lib/settings"], function (_exports, _tracking, _service, _settings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  function trackEvent(args) {
    if (typeof window.bpTrackEvent !== "undefined") {
      window.bpTrackEvent(args);
    }
  }
  function toAnalyticsCategory(slug) {
    return `${slug}`.toLowerCase().trim().replace(/\W+/g, "").replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase());
  }
  const setSessionItem = (key, value) => typeof window !== "undefined" && window.sessionStorage?.setItem(key, value);
  const getSessionItem = key => typeof window !== "undefined" && window.sessionStorage?.getItem(key) === "true";
  const key = slug => slug ? `stickybar-${slug}` : undefined;
  let AdhesionAd = _exports.default = (_class = class AdhesionAd extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "closed", _descriptor2, this);
      _defineProperty(this, "cachedAd", undefined);
    }
    get isPremiumUser() {
      if (!this.currentUser || !this.currentUser.custom_fields) {
        return false;
      }
      const premium = this.currentUser.custom_fields.premium_user;
      return premium === "true" || premium === true;
    }
    get campaignKey() {
      return key(this.cachedAd?.slug);
    }
    get campaignTitle() {
      return toAnalyticsCategory(this.cachedAd?.slug);
    }
    async getAd() {
      if (this.isPremiumUser) {
        return null;
      }
      if (this.cachedAd !== undefined) {
        return this.cachedAd;
      }
      const ad = await this._fetch();
      this.cachedAd = ad;
      if (!ad) {
        return;
      }
      if (getSessionItem(this.campaignKey)) {
        this.closed = true;
      } else {
        setSessionItem(this.campaignKey, false);
        this.closed = false;
      }
      return this.cachedAd;
    }
    close() {
      if (!this.cachedAd || !this.cachedAd.slug || this.closed) {
        return;
      }
      this.closed = true;
      setSessionItem(this.campaignKey, true);
      trackEvent({
        category: this.campaignTitle,
        action: "Close"
      });
    }
    impression() {
      if (!this.cachedAd || !this.cachedAd.slug || this.closed || this.isPremiumUser) {
        return;
      }
      trackEvent({
        category: this.campaignTitle,
        action: "Impression"
      });
    }
    click(e) {
      if (!this.cachedAd || !this.cachedAd.slug || this.closed || this.isPremiumUser) {
        return;
      }
      const text = `${e.target.textContent}`.trim();
      trackEvent({
        category: this.campaignTitle,
        action: "Click",
        payload: {
          text
        }
      });
    }
    async _fetch() {
      let ad;
      try {
        const resp = await fetch((0, _settings.mainSiteURLFor)("/na/a"), {
          headers: {
            "Content-Type": "application/json"
          }
        });
        const body = await resp.json();
        ad = body?.ad;
      } catch (ex) {
        console.error("Failed to load adhesion ad", ex); // eslint-disable-line no-console
      }
      return ad;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "closed", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class);
});