define("discourse/plugins/discourse-babypips-theme/discourse/templates/connectors/below-footer/adhesion-ad", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <AdhesionAd />
  */
  {
    "id": "iWUpip8M",
    "block": "[[[8,[39,0],null,null,null]],[],false,[\"adhesion-ad\"]]",
    "moduleName": "discourse/plugins/discourse-babypips-theme/discourse/templates/connectors/below-footer/adhesion-ad.hbs",
    "isStrictMode": false
  });
});