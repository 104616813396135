define("discourse/plugins/discourse-babypips-theme/discourse/templates/footer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <footer class="site-footer">
    <div class="legal">
      <div class="wrapper">
        <p class="copyright">Copyright &copy; {{current-year "noop"}} BabyPips.com LLC. All rights reserved.</p>
        <ul class="links">
          <PremiumOnly>
            <li><a href={{main-site-url-for "/subscribe/manage"}}>Manage Subscription</a></li>
          </PremiumOnly>
          <li><a href={{main-site-url-for "/contact"}}>Contact</a></li>
          <li><a href={{main-site-url-for "/contribute"}} rel="nofollow">Contribute 💰</a></li>
          <li><a href={{this.siteSettings.privacy_policy_url}}>Privacy Policy</a></li>
          <li><a href={{main-site-url-for "/risk-disclosure"}}>Risk Disclosure</a></li>
          <li><a href={{this.siteSettings.tos_url}}>Terms of Use</a></li>
          <li><button type="button" class="change-consent" id="pmLink">Privacy Manager</button></li>
        </ul>
      </div>
    </div>
  </footer>
  */
  {
    "id": "xiUvFj38",
    "block": "[[[10,\"footer\"],[14,0,\"site-footer\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"legal\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"wrapper\"],[12],[1,\"\\n      \"],[10,2],[14,0,\"copyright\"],[12],[1,\"Copyright © \"],[1,[28,[35,0],[\"noop\"],null]],[1,\" BabyPips.com LLC. All rights reserved.\"],[13],[1,\"\\n      \"],[10,\"ul\"],[14,0,\"links\"],[12],[1,\"\\n        \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n          \"],[10,\"li\"],[12],[10,3],[15,6,[28,[37,2],[\"/subscribe/manage\"],null]],[12],[1,\"Manage Subscription\"],[13],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n        \"],[10,\"li\"],[12],[10,3],[15,6,[28,[37,2],[\"/contact\"],null]],[12],[1,\"Contact\"],[13],[13],[1,\"\\n        \"],[10,\"li\"],[12],[10,3],[15,6,[28,[37,2],[\"/contribute\"],null]],[14,\"rel\",\"nofollow\"],[12],[1,\"Contribute 💰\"],[13],[13],[1,\"\\n        \"],[10,\"li\"],[12],[10,3],[15,6,[30,0,[\"siteSettings\",\"privacy_policy_url\"]]],[12],[1,\"Privacy Policy\"],[13],[13],[1,\"\\n        \"],[10,\"li\"],[12],[10,3],[15,6,[28,[37,2],[\"/risk-disclosure\"],null]],[12],[1,\"Risk Disclosure\"],[13],[13],[1,\"\\n        \"],[10,\"li\"],[12],[10,3],[15,6,[30,0,[\"siteSettings\",\"tos_url\"]]],[12],[1,\"Terms of Use\"],[13],[13],[1,\"\\n        \"],[10,\"li\"],[12],[10,\"button\"],[14,0,\"change-consent\"],[14,1,\"pmLink\"],[14,4,\"button\"],[12],[1,\"Privacy Manager\"],[13],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"current-year\",\"premium-only\",\"main-site-url-for\"]]",
    "moduleName": "discourse/plugins/discourse-babypips-theme/discourse/templates/footer.hbs",
    "isStrictMode": false
  });
});