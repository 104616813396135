define("discourse/plugins/discourse-babypips-theme/initializers/url", ["exports", "discourse/lib/url"], function (_exports, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "url",
    initialize() {
      _url.default.reopen({
        routeTo(path) {
          const oldForumsHTMLURLPattern = new RegExp("^https?://" + Discourse.BaseUrl + "/.+.html", "i");
          const oldForumsPHPURLPattern = new RegExp("^https?://" + Discourse.BaseUrl + "/(register|group|tags|forumdisplay|showthread|member|quiz).php.+", "i");

          // If path matches the old forums URL patterns, they should open as a new page
          if (oldForumsHTMLURLPattern.test(path) || oldForumsPHPURLPattern.test(path)) {
            // If URL anchor includes post ID, redirect to the actual post instead.
            const matches = /#post(\d+)$/i.exec(path);
            if (matches !== null && matches.length > 0) {
              path = "/vbulletin/post/" + matches[1];
            }
            document.location = path;
          } else {
            this._super(...arguments);
          }
        }
      });
    }
  };
});